import React from 'react'
import moment from 'moment'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  Layout,
  Helmet,
  Section,
  Container,
  Slider,
  GoogleMap,
  ContactForm,
} from 'components'

const ResidentielTemplate = ({
  data: { contentfulListingResidentiel },
  location,
}) => {
  let {
    residentialListingTitle,

    residentialListingImages,
    residentialListingImagesModal,
    imagesIntrieures,
    imagesIntrieuresModal,
    residentialListingSecteur,

    residentialListingTypes,
    residentialListingQty,
    residentialListingPrice,
    residentialListingStories,
    residentialListingAvailability,

    residentialListingsSpecs,
    residentialListingsNearby,

    // residentialListingsPlans,
    googleMap,
    projectUrl,

    residentialListingsAgent,
  } = contentfulListingResidentiel
  return (
    <Layout className="item-page">
      <Helmet
        title="Nomade, gestion immobilière"
        description="Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles résidentiels, en offrant des espaces locatifs novateurs et un service-client exemplaire."
        url={location.pathname}
      />
      <Section>
        <Container>
          <h1 className="red-line">{residentialListingTitle}</h1>
          <div className="flex images">
            {residentialListingImages && residentialListingImagesModal && (
              <Slider
                images={residentialListingImages}
                imagesModal={residentialListingImagesModal}
              />
            )}
            {imagesIntrieures && imagesIntrieuresModal && (
              <Slider
                images={imagesIntrieures}
                imagesModal={imagesIntrieuresModal}
              />
            )}
          </div>
          <div className="details">
            <div>
              <h1>Informations</h1>
              <ul>
                {residentialListingSecteur && (
                  <li>
                    <b>Secteur: </b>
                    {residentialListingSecteur}
                  </li>
                )}
                {residentialListingTypes && (
                  <li>
                    <b>Type de logements: </b>
                    {residentialListingTypes
                      .join(' -. ')
                      .split('.')
                      .map(item => item.toString().replace(' 1/2', '\u00BD'))}
                  </li>
                )}
                {residentialListingQty && (
                  <li>
                    <b>Nombre d’unités: </b>
                    {residentialListingQty} logements
                  </li>
                )}
                {residentialListingPrice && residentialListingPrice !== '_' && (
                  <li>
                    <b>Prix: </b>
                    {residentialListingPrice}
                  </li>
                )}
                {residentialListingStories && (
                  <li>
                    <b>Nombre d’étage: </b>
                    {residentialListingStories}
                  </li>
                )}
                {/*residentialListingAvailability && (
                  <li>
                    <b>Disponibilité: </b>
                    {moment(residentialListingAvailability).format('MMMM YYYY')}
                  </li>
                )*/}
              </ul>
            </div>
            <div>
              <h1>Caractéristiques</h1>
              <ul>
                {residentialListingsSpecs &&
                  residentialListingsSpecs.map(item => (
                    <li key={item}>{item}</li>
                  ))}
              </ul>
            </div>
            <div className="flex-reverse-mobile">
              <div className="map-object">
                {/*
                  <ul>
                    {residentialListingsPlans &&
                      residentialListingsPlans.map(({ id, title, file: { url }}) => (
                        <li className="file" key={id}>
                          <a
                            href={url}
                            rel="noopener nofollow"
                            target="_blank"
                            download
                          >
                            <DownloadIcon />{title}
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                */}
                <GoogleMap coords={googleMap} />
              </div>
              <div>
                <h1>À proximité</h1>
                <ul>
                  {residentialListingsNearby &&
                    residentialListingsNearby.map(item => (
                      <li key={item}>{item}</li>
                    ))}
                </ul>
                {projectUrl && (
                  <div>
                    <h1>Visitez le site web</h1>
                    <a
                      href={projectUrl}
                      target="_blank"
                      rel="no-follow noopener noreferrer"
                    >
                      {projectUrl}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section red className="consultants-section">
        <Container>
          <h1>Vous désirez visiter ou louer un logement?</h1>
          {/*<div className="flex consultants">
            {residentialListingsAgent &&
              residentialListingsAgent.map(
                ({
                  id,
                  image,
                  managerName,
                  managerTitle,
                  managerOfficePhone,
                  managerMobilePhone,
                  managerEmail,
                }) => (
                  <div className="flex consultant" key={id}>
                    {image && <Img fluid={image.fluid} />}
                    <div className="consultant-info">
                      <h3>{managerName}</h3>
                      <a href={`tel:${managerMobilePhone}`}>
                        {managerMobilePhone}
                      </a>
                      <a href={`mailto:${managerEmail}`}>{managerEmail}</a>
                    </div>
                  </div>
                )
              )}
                </div>*/}
        </Container>
      </Section>
      <Section grey>
        <Container>
          <h2>Contact</h2>
          <ContactForm
            title={residentialListingTitle}
            name="Residential Form"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default ResidentielTemplate

export const pageQuery = graphql`
  query pageQueryResidentiel($id: String) {
    contentfulListingResidentiel(id: { eq: $id }) {
      id
      residentialListingTitle
      slug
      # Photos
      imagesIntrieures: imagesIntrieures2 {
        id
        fluid(maxWidth: 600, maxHeight: 400, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      imagesIntrieuresModal: imagesIntrieures2 {
        id
        fluid(maxWidth: 1920, maxHeight: 1280, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      residentialListingImages {
        id
        fluid(maxWidth: 600, maxHeight: 400, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      residentialListingImagesModal: residentialListingImages {
        id
        fluid(maxWidth: 1920, maxHeight: 1280, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid
        }
      }
      residentialListingSecteur
      residentialListingTypes
      residentialListingQty
      residentialListingPrice
      residentialListingStories
      residentialListingAvailability
      # Charasteristics
      residentialListingsSpecs
      # A proximite
      residentialListingsNearby
      # Project Url
      projectUrl
      # Map
      googleMap {
        lon
        lat
      }
      # Plans
      #residentialListingsPlans {
      #  id
      #  title
      #  file {
      #    url
      #    fileName
      #    contentType
      #  }
      #}
      # Managers
      residentialListingsAgent {
        id
        image {
          fluid(maxWidth: 200, maxHeight: 200, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid
          }
        }
        managerName
        managerTitle
        managerOfficePhone
        managerMobilePhone
        managerEmail
      }
    }
  }
`
